<script>
    import { scrollto } from "svelte-scrollto";
    import IndexNavbar from "components/Navbars/IndexNavbar.svelte";
    import Footer from "components/Footers/Footer.svelte";
    import { onMount } from "svelte";

    import { Highlight } from "svelte-highlight";
    import { python } from "svelte-highlight/languages";
    import { github } from "svelte-highlight/styles";

    import Formater from "./codeFormat.svelte";

    onMount(() => {
    window.scrollTo(0, 0);
  });

    // import { anOldHope } from "svelte-highlight/styles";

    const patternVue = "/assets/img/numArrays.jpg";
    const waves = "/assets/img/wave.png";
    const aTitle = "Yield for a While";
    const headText2 =
        "This yield statement comes in handy here by outputting (constant, x, and z) while x is > 0.";
    const pythonTerm =
        "Now we are done with the function and you are ready to print some results! Copy the code below then run: python3 nameOfyourFile.py in your terminal.";

    $: coda = `def get_derivative(constant, x):
    z = 0
    while x > 0:
        z += 1
        constant *= x
        x -= 1
        yield (constant, x, z)`;

    $: code = `def f_of_x(constant,var1):
    if var1 == 0:
        return f"{constant}"
    if var1 >= 1:
        for values in get_derivative(constant, var1):
            value2 = f"{values[2]} Degree Derivative: {values[0]} x**{values[1]}"
            print(value2) # if

    else:
        return "Edge Case"`;

    $: code3 = `if __name__ == '__main__':
    a, b = map(int, input().split())
    print(f_of_x(a, b))`;
</script>

<svelte:head>
    {@html github}
</svelte:head>

<!-- <svelte:head>
    <title>About</title>
  </svelte:head> -->

<IndexNavbar />

<!-- <div class="flex flexrow hidden lg:block">
    <section class="section2">
        <div class="sineWaves"></div>
    </section>
</div>
<div class="flex flexrow md:hidden sm:block">
</div> -->

<div class="container px-5 pt-24 mx-auto">
    <div class="flex flex-col text-center w-full mb-12">
        <h1
            class="sm:text-3xl title-font mt-32 mb-4 font-semibold text-4xl text-gray-700">
            {aTitle}
        </h1>
        <div flex flex-row>
            <span
                class="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-600">
                This article should help you get familiar with yielding values
                from a loop
            </span>
            <!-- <a
            href="https://developers.google.com/edu/python/lists"
            ><span>[List]</span></a> -->
        </div>
    </div>
</div>

<!-- First Text -->
<div class="flex flex-row w-screen relative justify-center items-center">
    <div class="w-8/12">
        <span class="pl-16 mx-auto leading-relaxed text-base text-gray-600">
            To show how
        </span>
        <span class="mx-auto leading-relaxed text-base text-gray-600">
            to do this I made a function that iteratively calculates the
            derivate of a power function f(x).
        </span>
        <span class="mx-auto leading-relaxed text-base text-gray-600">To get
            started open up your Python editor and define a function:
            get_derivative() that will take in a (constant) and a variable (x)
            by copying the code below. The (z) variable will serve to count the
            degrees of derivatives that were calculated.
        </span>
        <p class="mx-auto leading-relaxed text-base text-gray-600">
            Notice how we have our while loop setup and the logic below it. What
            we are stating here is the aX^n to n(aX)^n-1 derivative power rule.
            At the bottom of our code block we have our yield statement yielding
            our constant, x and z values.
        </p>
    </div>
</div>

<!-- Block of Code -->
<div class="flex flex-row w-screen relative justify-center items-center">
    <div class="w-8/12">
        <!-- <div class="p-2 code text-sm font-mono px-8 py-6 bg-gray-200 flex flex-row justify-between"> -->
        <div class="p-2 pl-4 code text-sm font-mono px-8 py-6">
            <!-- <Highlight language={python} {code} /> -->

            <Formater code={coda} />
        </div>
    </div>
</div>

<!-- Second Text -->
<div class="flex flex-row w-screen relative justify-center items-center">
    <div class="w-8/12">
        <span class="mx-auto leading-relaxed text-base text-gray-600">
            {headText2}
        </span>
        <span class="mx-auto leading-relaxed text-base text-gray-600">
            Now copy function: f_of_x() that takes in (constant) and (var1). Once
            you've done that, before continuing to read see if you can understand
            the logic below, it's an if statement so you might be familiar with
            it already. Here we are checking to see if the exponent of var1 is 0
            then return your constant, if its greater than or equal to one then
            send that value to our get_derivative() function, else then we have
            an edge case.
        </span>
        <span class="mx-auto leading-relaxed text-base text-gray-600">To get
            started An example would be 4x^3 (you may also see 4x**3). Here we
            would have (4*3) and (3-1) to yield 12x^2. Can you guess what (z) is
            at this stage?...the answer is 1, since we only took the first
            degree derivate also denoted as: f'(x).
        </span>
        <p class="mx-auto leading-relaxed text-base text-gray-600">
            Something to note here is that under "if var1 &gt;= 1" we are
            looping through our get derivative function. This is so we may
            access the values being outputted by the yield statement.
        </p>
    </div>
</div>
<!-- Second Block of Code -->
<div class="flex flex-row w-screen relative justify-center items-center">
    <div class="w-8/12">
        <!-- <div class="p-2 code text-sm font-mono px-8 py-6 bg-gray-200 flex flex-row justify-between"> -->
        <div class="p-2 pl-4 code text-sm font-mono px-8 py-6">
            <!-- <Highlight language={python} {code} /> -->

            <Formater {code} />
        </div>
    </div>
</div>
<div class="flex flex-row w-screen relative justify-center items-center">
    <div class="w-8/12">
        <span class="mx-auto leading-relaxed text-base text-gray-600">
            {pythonTerm}
        </span>
        <span class="mx-auto leading-relaxed text-base text-gray-600">
            After you run it type in: 4 3 in your terminal not seperated by a
            comma, since we are using split to split our inputs by the
            whitespace in the middle.</span>
    </div>
</div>

<!-- Third Block of Code -->
<div class="flex flex-row w-screen relative justify-center items-center">
    <div class="w-8/12">
        <!-- <div class="p-2 code text-sm font-mono px-8 py-6 bg-gray-200 flex flex-row justify-between"> -->
        <div class="p-2 pl-4 code text-sm font-mono px-8 py-6">
            <!-- <Highlight language={python} {code} /> -->

            <Formater code={code3} />
        </div>
    </div>
</div>

<!-- Last Block of Text -->
<div class="flex flex-row w-screen relative justify-center items-center">
    <div class="w-8/12">
        <p class="mx-auto leading-relaxed text-base text-gray-600">
            After you run it you should get: 4 3
        </p>
        <p class="mx-auto leading-relaxed text-base text-gray-600">
            1 Degree Derivative: 12 x**2
        </p>
        <p class="mx-auto leading-relaxed text-base text-gray-600">
            2 Degree Derivative: 24 x**1
        </p>
        <p class="mx-auto leading-relaxed text-base text-gray-600">
            3 Degree Derivative: 24 x**0
        </p>
        <br />
        <p class="mx-auto leading-relaxed text-base text-gray-600">
            As your output. Try this example again with different numbers and
            follow the logic while calculating the derivative yourself see if
            you can follow the inputs being piped from one function to another.
            The important concept here is for you to learn how to pipe values
            from a loop and insert them into another function so that you can
            add more logic as needed.
        </p>
        <br>
        <p class="mx-auto leading-relaxed text-base text-gray-600">When going through tutorials like these, generic examples are usually provided because the example is arbitrary. The real power comes from you being able to apply these concepts in the "real" world and not just for some fizz buzz problem.</p>
    </div>
</div>

<div class="pb-20"><br /></div>
<div class="pb-20"><br /></div>

<Footer />
