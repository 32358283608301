<script>
    import IndexNavbar from "components/Navbars/IndexNavbar.svelte";
    import Footer from "components/Footers/Footer.svelte";
    import { onMount } from "svelte";
    onMount(() => {
        window.scrollTo(0, 0);
    });
    const aTitle = "Impact Matters";
</script>

<svelte:head>
    <title>Impact Matters</title>
    <meta name="an article on communication skills">
</svelte:head>

<IndexNavbar />

<div class="container px-5 pt-24 mx-auto">
    <div class="flex flex-col text-center w-full mb-12">
        <h1
            class="sm:text-3xl title-font mt-32 mb-4 font-semibold text-4xl text-gray-700"
        >
            {aTitle}
        </h1>
        <div flex flex-row>
            <span
                class="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-600"
            >
                This article is over the topic of communication.
            </span>
        </div>
    </div>
</div>

<div class="flex flex-row w-screen relative justify-center items-center">
    <div class="w-8/12">
        <span class="pl-16 mx-auto leading-relaxed text-base text-gray-600">
            {"Growing up my communication skills were lacking. My problem was that I valued individuality and I believed reaching out to others with your problems was not okay. In my mind sharing things about yourself was only for girls. Until recently I still held these viewpoints, even with my closest friends I never overshared my personal problems. I am still uncomfortable sharing family issues etc.. with others, but I am able to communicate things that may be affecting me career wise and relationship wise. If you never communicate on a regular basis I believe your social skills will decline and you will grow up unaware of your deficiencies. If you are in a technical field, you may place a strong emphasis on your hard skills while undervaluing your soft skills. Soft skills are just as important as your hard skills, and may become even more important as you get older. I would recommend everyone take a technical writing course as well as a speech course. Being able to formulate your thoughts, and conveying them in a concise manner is a very valuable skill that can spill over into many aspects of life."}
        </span>
    </div>
</div>
<div class="pb-20"><br /></div>
<div class="pb-20"><br /></div>

<Footer />
