<script>
  import { link } from "svelte-routing";

  // core components
  import IndexDropdown from "components/Dropdowns/IndexDropdown.svelte";

  let navbarOpen = false;

  const grLogo = "/assets/img/signature.png";

  function setNavbarOpen() {
    navbarOpen = !navbarOpen;
  }
</script>

<nav
  class="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow"
>
  <div
    class="container px-4 mx-auto flex flex-wrap items-center justify-between"
  >
    <div
      class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
    >
      <!-- <a
        use:link
        class="text-gray-800 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase"
        href="/"
      >
        GR
      </a> -->

      <button
        class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
        type="button"
        on:click={setNavbarOpen}
      >
        <i class="fas fa-bars" />
      </button>
    </div>
    <div
      class="lg:flex flex-grow items-center {navbarOpen ? 'block' : 'hidden'}"
      id="example-navbar-warning"
    >
      <a href="/" style="width: 44%">
        <img src={grLogo} alt="..." style="height: 10%; width: 48%" />
      </a>
      <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
        <li class="flex items-center">
          <IndexDropdown />
        </li>
        <!-- <li class="flex items-center">
          <a
            class="hover:text-gray-600 text-gray-800 px-3 py-2 flex items-center text-xs uppercase font-bold"
            href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdemos.creative-tim.com%2Fnotus-svelte%2F%23%2F"
            target="_blank"
          >
            <i class="text-gray-500 fab fa-facebook text-lg leading-lg" />
            <span class="lg:hidden inline-block ml-2">Share</span>
          </a>
        </li> -->
        <!-- <li class="flex items-center">
          <a
            class="hover:text-blue-500 text-gray-800 px-3 py-2 flex items-center text-xs uppercase font-bold"
            href="https://twitter.com/georgereyes103"
            target="_blank"
          >
            <i class="text-blue-500 fab fa-twitter text-lg leading-lg" />
            <span class="lg:hidden inline-block ml-2">Twitter</span>
          </a>
        </li> -->

        <li class="flex items-center">
          <a
            class="hover:text-blue-600 text-gray-800 px-3 py-2 flex items-center text-xs uppercase font-bold"
            href="https://www.linkedin.com/in/george-a-reyes/"
            target="_blank"
          >
            <i class="text-blue-600 fab fa-linkedin text-lg leading-lg" />
            <span class="lg:hidden inline-block ml-2">LinkedIn</span>
          </a>
        </li>

        <li class="flex items-center">
          <a
            class="hover:text-gray-900 text-gray-800 px-3 py-2 flex items-center text-xs uppercase font-bold"
            href="https://github.com/reyesGeorge"
            target="_blank"
          >
            <i class="text-gray-900 fab fa-github text-lg leading-lg" />
            <span class="lg:hidden inline-block ml-2">GitHub</span>
          </a>
        </li>

        <!-- <li class="flex items-center">
          <a
          href="/contracting"
          >
          <button
            class="bg-orange-500 text-white active:bg-orange-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
            type="button">
            Contracting
          </button>
        </a>
        </li> -->
      </ul>
    </div>
  </div>
</nav>
