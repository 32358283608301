<script>
  import { Link } from "svelte-routing";

  // core components
  import IndexNavbar from "components/Navbars/IndexNavbar.svelte";
  import Footer from "components/Footers/Footer.svelte";
  import { LottiePlayer } from "@lottiefiles/svelte-lottie-player";
  import { scrollto } from "svelte-scrollto";

  const patternVue = "/assets/img/hero.png";
  const pinkFlamingo = "/assets/img/pinkFlamingo.png";
  const neonPineapple = "/assets/img/neonPineapple.png";
  const lettucePane = "/assets/img/lettucePane.png";
  const popTiger = "/assets/img/popTiger.png";
  const alexGreat = "/assets/img/alexGreat.png";
  const oceanCool = "/assets/img/oceanCool.png";
  
  const coralsPic = "/assets/img/corals.png";
  const junglePic = "/assets/img/jungle.png";
  const wavePic = "/assets/img/wavering.png";
  const fractalPic = "/assets/img/fractal.png";

  let srcImage = "/assets/img/hero.png";

  const churnPic = "/assets/img/snowForest.jpg";
  const yielPic = "/assets/img/yieldW.jpg";
  const vigilPic = "/assets/img/vigil.jpg";
  const componentProfileCard = "/assets/img/component-profile-card.png";
  const componentInfoCard = "/assets/img/component-info-card.png";
  const componentInfo2 = "/assets/img/component-info-2.png";
  const componentMenu = "/assets/img/component-menu.png";
  const componentBtnPink = "/assets/img/component-btn-pink.png";
  const documentation = "/assets/img/documentation.png";
  const login = "/assets/img/login.jpg";
  const profile = "/assets/img/profile.jpg";
  const landing = "/assets/img/landing.jpg";
  export let location;

  let controlsLayout = [
    "previousFrame",
    "playpause",
    "stop",
    "nextFrame",
    "progress",
    "frame",
    "loop",
    "spacer",
    "background",
    "snapshot",
    "zoom",
    "info",
  ];

  let count = 0;

  function handleClick(event) {
    count += 1;
    if (count > 8) {
      count = 0;
    }
    if (count == 0) {
      srcImage = oceanCool;
    } else if (count == 1) {
      srcImage = coralsPic;
    } else if (count == 2) {
      srcImage = alexGreat;
    } else if (count == 3) {
      srcImage = pinkFlamingo;
    } else if (count == 4) {
      srcImage = neonPineapple;
    } else if (count == 5) {
      srcImage = popTiger;
    } else if (count == 6) {
      srcImage = lettucePane;
    } else if (count == 7) {
      srcImage = junglePic;
    } else if (count == 8) {
      srcImage = patternVue;
    } else if (count == 9) {
      srcImage = wavePic;
    } else if (count == 10) {
      srcImage = fractalPic;
    }
  }
</script>


<IndexNavbar />
<section class="header relative pt-16 items-center flex h-screen max-h-860-px">
  <div class="container mx-auto items-center flex flex-wrap">
    <div class="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
      <div class="pt-32 sm:pt-0">
        <h2 class="font-semibold text-4xl text-gray-700">
          Hi, I'm George! A Software Engineer
        </h2>
        <p class="mt-4 text-lg leading-relaxed text-gray-600">
          with a passion for learning new things. I am a self-taught developer
          with a background in business. I'm currently working on <a
            href="https://www.prolifvc.com/">Prolif.</a
          >
          I am in charge of our production application, and help improve all of our
          processes to have a more robust and scalable product. I have around 5 years
          of experience building software.
          <!-- I am seeking a role where I can utilize a blend of my business and
          technical skills alongside my creativity to advise or take impactful
          decisions. -->
          <!-- <a
            href="https://tailwindcss.com/?ref=creativetim"
            class="text-gray-700"
            target="_blank"
          >
            Tailwind CSS
          </a> -->
        </p>
        <div class="mt-12">
          <!-- svelte-ignore a11y-missing-attribute -->
          <a
            style="cursor: pointer;"
            use:scrollto={("#scroll-element", { offset: 750 })}
            class="text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blue-500 active:bg-blue-700 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
          >
            Learn More
          </a>
          <!-- svelte-ignore a11y-missing-attribute -->
          <a
            style="cursor: pointer;"
            use:scrollto={"#scroll-insights"}
            class="ml-3 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-gray-800 active:bg-gray-700 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
          >
            Insights
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="hidden lg:block">
    <a
    href="https://translate.google.com/?sl=auto&tl=en&text=%E8%81%98%E8%AF%B7%E4%B9%94%E6%B2%BB&op=translate"
    >
      <img
      class="absolute top-0 b-auto right-0 pt-16 sm:w-none -mt-48 sm:mt-0 w-10/12 max-h-860-px"
      style="width: 35%; height: 95%; margin-right: 8%; margin-top: 2%; cursor: pointer;"
      src={patternVue}
      alt="..."
      />
    </a>

    <!-- <img
      class="absolute top-0 b-auto right-0 pt-16 sm:w-none -mt-48 sm:mt-0 w-10/12 max-h-860-px"
      style="width: 35%; height: 95%; margin-right: 8%; margin-top: 2%; cursor: pointer;"
      src={srcImage}
      alt="..."
    /> -->

    <!-- svelte-ignore a11y-missing-attribute -->
    <!-- <div style="margin-top: 55rem; margin-left: -15rem">
      <a
        style="cursor: pointer;"
        on:click={handleClick}
        class="text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blue-500 active:bg-blue-700 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
      >
        Next Image
      </a>
    </div> -->
    <!-- <button on:click="{handleClick}">
      Next Image
    </button> -->
  </div>
  <!-- <div class="hidden lg:block">
    <img
      class="absolute top-0 b-auto right-0 pt-16 sm:w-none -mt-48 sm:mt-0 w-10/12 max-h-860-px"
      style="width: 35%; height: 95%; margin-right: 8%; margin-top: 2%; cursor: pointer;"
      src={patternVue}
      alt="..."
      />
  </div> -->
</section>

<section class="mt-48 md:mt-44 pb-40 relative bg-gray-200">
  <div
    id="scroll-element"
    class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
    style="transform: translateZ(0);"
  >
    <svg
      class="absolute bottom-0 overflow-hidden"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      version="1.1"
      viewBox="0 0 2560 100"
      x="0"
      y="0"
    >
      <polygon
        class="text-gray-200 fill-current"
        points="2560 0 2560 100 0 100"
      />
    </svg>
  </div>
  <div class="container mx-auto">
    <div class="flex flex-wrap items-center">
      <div
        class="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32"
      >
        <div
          class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blue-500"
        >
          <img
            alt="..."
            src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80"
            class="w-full align-middle rounded-t-lg"
          />
          <blockquote class="relative bg-blue-500 p-8 mb-4">
            <svg
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 583 95"
              class="absolute left-0 w-full block h-95-px -top-94-px"
            >
              <polygon
                points="-30,95 583,95 583,65"
                class="text-blue-500 fill-current"
              />
            </svg>
            <h4 class="text-xl font-bold text-white">
              Primary tools
            </h4>
            <p class="text-md font-light mt-2 text-white">
              I have backend experience in Python, Vercel, Microsoft Azure, FastAPI and
              frontend experience using TypeScript, React, Dash, HTML and CSS.
            </p>
            <!-- <p class="text-md font-light mt-2 text-white">
              From idea generation to data derivation. I have backend experience
              in Python building data pipelines in Azure Cosmos DB/FastAPI and
              frontend/visualization experience using React, Dash, Bokeh and
              HTML/CSS. Data analysis or product development im your guy!
            </p> -->
            <!-- <img
            alt="..."
            src="/assets/img/me.png"
            class="w-full align-middle rounded-lg absolute shadow-lg max-w-210-px left-260-px -top-160-px"
            style="margin-left: 14px; z-index: -1"
          /> -->
          </blockquote>
        </div>
      </div>

      <div class="w-full md:w-6/12 px-4">
        <div class="flex flex-wrap">
          <div class="w-full md:w-6/12 px-4">
            <div class="relative flex flex-col mt-4">
              <div class="px-4 py-5 flex-auto">
                <div
                  class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                >
                  <i class="fas fa-campground" />
                </div>
                <h6 class="text-xl mb-1 font-semibold">Building</h6>
                <p class="mb-4 text-gray-600">
                  <!-- If you need a scaleable platform built, custom feature work, or a fresh set of eyes for an analysis on a large data set I can help. -->
                  {"I am working on a portal that allows you to invest in the early stage startups we onboard. Businesses that are looking to raise capital, list with us, so they may raise a crowdfunded round."}
                </p>
              </div>
            </div>
            <div class="relative flex flex-col min-w-0">
              <div class="px-4 py-5 flex-auto">
                <div
                  class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                >
                  <i class="fas fa-file-alt" />
                </div>
                <h6 class="text-xl mb-1 font-semibold">Contact</h6>
                <p class="mb-4 text-gray-600">
                  <!-- This website was built by me in 2 days! Inquiries welcome that relate to my experience, relating to Software Development, Data Analysis or Growth Marketing. -->
                  Contact me if you want to discuss industry best practices, the economy or need to get in touch.
                </p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-6/12 px-4">
            <div class="relative flex flex-col min-w-0 mt-4">
              <div class="px-4 py-5 flex-auto">
                <div
                  class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                >
                  <i class="fas fa-shipping-fast" />
                </div>
                <h6 class="text-xl mb-1 font-semibold">Packages/Frameworks</h6>
                <p class="mb-4 text-gray-600">
                  Work faster not slower! There is no reason to re-invent the
                  wheel, these are some of the "wheels" that I use to get my
                  projects rolling.
                </p>
              </div>
            </div>
            <div class="relative flex flex-col min-w-0">
              <div class="px-4 py-5 flex-auto">
                <div
                  class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                >
                  <i class="fas fa-globe-americas" />
                </div>
                <h6 class="text-xl mb-1 font-semibold">Insights</h6>
                <p class="mb-4 text-gray-600">
                  This is a section where I have relevant insights I think are
                  worth sharing. My opinions are my own curated as a collection
                  of my life experiences up to this point.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container mx-auto overflow-hidden pb-20">
    <div class="flex flex-wrap items-center">
      <div class="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto mt-48">
        <div
          class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
        >
          <i class="fas fa-campground" />
        </div>
        <h3 class="text-3xl mb-2 font-semibold leading-normal">Building</h3>
        <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
          <!-- If you need a scaleable platform built, custom feature work, or a fresh set of eyes for an analysis on a large data set I can help. -->
          These are some of my favorite tools.
        </p>
        <div class="block pb-6">
          <span
            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2"
          >
            React
          </span>
          <span
            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2"
          >
            FastAPI
          </span>
          <span
            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2"
          >
            Plotly/Dash
          </span>
          <span
            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2"
          >
            Flutter
          </span>
          <span
            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2"
          >
            Azure
          </span>
        </div>
        <!-- <a
          href="https://www.creative-tim.com/learning-lab/tailwind/svelte/alerts/notus?ref=ns-index"
          target="_blank"
          class="font-bold text-gray-800 hover:text-gray-600 ease-linear transition-all duration-150">
          View All
          <i class="fa fa-angle-double-right ml-1 leading-relaxed" />
        </a> -->
      </div>

      <div class="w-full md:w-5/12 px-4 mr-auto ml-auto mt-32">
        <div class="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">
          <LottiePlayer
            src="/assets/img/dataAnalystLotti.json"
            autoplay={true}
            loop={true}
            controls={false}
            renderer="svg"
            background="transparent"
            height={600}
            width={600}
            {controlsLayout}
          />
          <!-- <img
            alt="..."
            src="{componentBtn}"
            class="w-full align-middle rounded absolute shadow-lg max-w-100-px left-145-px -top-29-px z-3"
          />
          <img
            alt="..."
            src="{componentProfileCard}"
            class="w-full align-middle rounded-lg absolute shadow-lg max-w-210-px left-260-px -top-160-px"
          />
          <img
            alt="..."
            src="{componentInfoCard}"
            class="w-full align-middle rounded-lg absolute shadow-lg max-w-180-px left-40-px -top-225-px z-2"
          />
          <img
            alt="..."
            src="{componentInfo2}"
            class="w-full align-middle rounded-lg absolute shadow-2xl max-w-200-px -left-50-px top-25-px"
          />
          <img
            alt="..."
            src="{componentMenu}"
            class="w-full align-middle rounded absolute shadow-lg max-w-580-px -left-20-px top-210-px"
          />
          <img
            alt="..."
            src="{componentBtnPink}"
            class="w-full align-middle rounded absolute shadow-xl max-w-120-px left-195-px top-95-px"
          /> -->
        </div>
      </div>
    </div>

    <div class="flex flex-wrap items-center pt-32">
      <div class="w-full md:w-6/12 px-4 mr-auto ml-auto mt-32">
        <div class="justify-center flex flex-wrap relative">
          <div class="my-4 w-full lg:w-6/12 px-4">
            <a href="https://reactjs.org/" target="_blank">
              <div class="bg-gray-700 shadow-lg rounded-lg text-center p-8">
                <img
                  alt="..."
                  class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                  style="height: 60px;"
                  src="/assets/img/reactLogo.png"
                />
                <p class="text-lg text-white mt-4 font-semibold">React</p>
              </div>
            </a>

            <a href="https://docs.docker.com/" target="_blank">
              <div
                class="shadow-lg rounded-lg text-center p-8 mt-8"
                style="background-color: #031f30"
              >
                <img
                  alt="icon for Docker"
                  class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                  src="/assets/img/dockerICON.png"
                  style="height: 62px;"
                />
                <p class="text-lg text-white mt-4 font-semibold">Docker</p>
              </div>
            </a>

            <a href="https://pandas.pydata.org/about/" target="_blank">
              <div
                class="shadow-lg rounded-lg text-center p-8 mt-8"
                style="background-color: #130654"
              >
                <img
                  alt="..."
                  class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                  src="/assets/img/pandas.png"
                  style="height: 62px;"
                />
                <p class="text-lg text-white mt-4 font-semibold">pandas</p>
              </div>
            </a>
            <!-- <a
              href="https://www.statsmodels.org/stable/index.html"
              target="_blank">
              <div
                class="shadow-lg rounded-lg text-center p-8 mt-8"
                style="background-color: #3f51b5">
                <img
                  alt="..."
                  class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                  src="/assets/img/statsmodels.png" />
                <p class="text-lg text-white mt-4 font-semibold">statsmodels</p>
              </div>
            </a> -->
            <a href="https://dash.plotly.com/" target="_blank">
              <div
                class="bg-gray-800 shadow-lg rounded-lg text-center p-8 mt-8"
              >
                <img
                  alt="..."
                  class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                  src="/assets/img/plotly.png"
                />
                <p class="text-lg text-white mt-4 font-semibold">
                  Plotly & Dash Framework
                </p>
              </div>
            </a>
          </div>

          <div class="my-4 w-full lg:w-6/12 px-4 lg:mt-16">
            <a href="https://fastapi.tiangolo.com/" target="_blank">
              <div
                class="shadow-lg rounded-lg text-center p-8"
                style="background-color: #009485"
              >
                <img
                  alt="..."
                  class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                  style="height: 60px;"
                  src="/assets/img/fastAPILOGO.png"
                />
                <p class="text-lg text-white mt-4 font-semibold">FastAPI</p>
              </div>
            </a>
            <a href="https://flutter.dev/" target="_blank">
              <div
                class="shadow-lg rounded-lg text-center p-8 mt-8"
                style="background-color: #0468d7"
              >
                <img
                  alt="..."
                  class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                  src="/assets/img/flutterICON.png"
                />
                <p class="text-lg text-white mt-4 font-semibold">Flutter</p>
              </div>
            </a>
            <a href="https://azure.microsoft.com/en-us/" target="_blank">
              <div
                class="shadow-lg rounded-lg text-center p-8 mt-8"
                style="background-color: #242429"
              >
                <img
                  alt="..."
                  class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                  src="/assets/img/azureICON.png"
                />
                <p class="text-lg text-white mt-4 font-semibold">Azure</p>
              </div>
            </a>
            <a href="https://scikit-learn.org/" target="_blank">
              <div
                class="bg-blue-500 shadow-lg rounded-lg text-center p-8 mt-8"
              >
                <img
                  alt="..."
                  class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                  style="height: 62px;"
                  src="/assets/img/scikit.png"
                />
                <p class="text-lg text-white mt-4 font-semibold">
                  scikit-learn
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div class="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
        <div
          class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
        >
          <i class="fas fa-shipping-fast text-xl" />
        </div>
        <h3 class="text-3xl mb-2 font-semibold leading-normal">
          Packages/Frameworks
        </h3>
        <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
          I like to work faster not slower! There is no reason to re-invent the
          wheel, these are some of the "wheels" that I use to get my projects
          rolling.
        </p>
        <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
          I like to solve problems either building a paywall, setting up an API
          or configuring a database...what are we trying to achieve and what
          stakeholders are involved.
        </p>
        <div class="block pb-6">
          <span
            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2"
          >
            Python
          </span>
          <span
            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2"
          >
            TypeScript
          </span>
          <span
            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2"
          >
            SQL
          </span>
          <span
            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2"
          >
            Dart
          </span>
        </div>
        <!-- <a
          href="https://www.creative-tim.com/learning-lab/tailwind/svelte/alerts/notus?ref=ns-index"
          target="_blank"
          class="font-bold text-gray-800 hover:text-gray-600 ease-linear transition-all duration-150">
          View all
          <i class="fa fa-angle-double-right ml-1 leading-relaxed" />
        </a> -->
      </div>
    </div>
  </div>

  <br />
  <br />
  <br />
  <div class="container mx-auto px-4 pb-32 pt-48">
    <br />
    <!-- <div class="items-center flex flex-wrap">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
          <div class="w-full lg:w-6/12 px-4">
            <div
              class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
              <i class="fas fa-file-alt text-xl" />
            </div>
            <h3 class="text-3xl font-semibold">Contact</h3>

            <div class="relative w-full mb-3 mt-8">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                for="full-name">
                Full Name
              </label>
              <input
                id="full-name"
                type="text"
                class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                placeholder="Full Name" />
            </div>

            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                for="email">
                Email
              </label>
              <input
                id="email"
                type="email"
                class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                placeholder="Email" />
            </div>

            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                for="message">
                Message
              </label>
              <textarea
                id="message"
                rows="4"
                cols="80"
                class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                placeholder="Type a message..." />
            </div>
            <div class="text-center mt-6">
              <button
                class="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button">
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <iframe
      title="Contact Form"
      class="airtable-embed airtable-dynamic-height"
      src="https://airtable.com/embed/shrAYX7Mvd6QYpq15?backgroundColor=blue"
      frameborder="0"
      onmousewheel=""
      width="100%"
      height="755"
      style="background: transparent; border: 1px solid #ccc;"
    />
  </div>
</section>

<section class="block relative z-1 bg-gray-700" />

<section id="scroll-insights" class="py-20 bg-gray-700 overflow-hidden">
  <div class="container mx-auto pb-48">
    <div id="scroll-insights" class="flex flex-wrap justify-center">
      <div class="w-full md:w-5/12 px-4 md:px-4 ml-auto mr-auto md:mt-64">
        <div
          class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
        >
          <i class="fas fa-lightbulb text-xl" />
        </div>
        <h3 class="text-3xl mb-2 font-semibold leading-normal text-white">
          Insights
        </h3>
        <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-500">
          <!-- <a
            href="https://tailwindcss.com/?ref=creativetim"
            class="text-gray-400"
            target="_blank"
          >
            Tailwind CSS
          </a> -->
          This is a section where I have relevant insights I think are worth sharing.
          My opinions are my own curated as a collection of my life experiences up
          to this point.
        </p>
        <br />
        <br />
        <!-- <a
          href="https://github.com/creativetimofficial/notus-svelte?ref=ns-index"
          target="_blank"
          class="github-star mt-4 inline-block text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-gray-800 active:bg-gray-700 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150">
          Github Star
        </a> -->
      </div>
      <div class="w-full md:w-4/12 px-4 mr-auto ml-auto mt-32 relative">
        <i
          class="hidden lg:block fas fa-globe-americas text-gray-800 text-55 absolute -top-150-px -right-100 left-auto opacity-80"
        />
      </div>
    </div>
  </div>
</section>

<section class="pb-16 bg-gray-300 relative pt-8">
  <div
    class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
    style="transform: translateZ(0);"
  >
    <svg
      class="absolute bottom-0 overflow-hidden"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      version="1.1"
      viewBox="0 0 2560 100"
      x="0"
      y="0"
    >
      <polygon
        class="text-gray-300 fill-current"
        points="2560 0 2560 100 0 100"
      />
    </svg>
  </div>

  <div class="container mx-auto">
    <div
      class="flex flex-wrap justify-center rounded-lg -mt-48 py-8 px-12 relative z-10 del-back"
      style="margin-top: -22rem;"
    >
      <div class="w-full text-center lg:w-8/12">
        <!-- <h2 class="font-semibold text-4xl">Articles</h2> -->
        <div class="justify-center text-center flex flex-wrap mt-24">
          <div class="w-full md:w-6/12 px-12 md:px-4">
            <!-- <p class="text-lg leading-relaxed mt-4 mb-4 text-gray-600">
            </p> -->
            <br />
            <br />
          </div>
        </div>
        <div class="container mx-auto">
          <div class="justify-center flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4 -mt-24">
              <div class="flex flex-wrap justify-center items-center">
                <!-- <div class="w-full lg:w-11/12 px-4">
                  <Link to="/sociopathcia">
                    <div
                      class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg py-2"
                    >
                      <h5 class="text-xl font-semibold pb-4 text-center">
                        Being Fluid Like Water, as a Sociopath
                      </h5>
                      <span
                        class="mx-auto leading-relaxed text-base text-gray-600"
                        >an article over the CIA case study on me and what it's like to be me
                      </span>
                    </div>
                  </Link>
                </div> -->
                <div class="w-full lg:w-11/12 px-4">
                  <Link to="/communication">
                    <div
                      class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg py-2"
                    >
                      <h5 class="text-xl font-semibold pb-4 text-center">
                        Communication
                      </h5>
                      <span
                        class="mx-auto leading-relaxed text-base text-gray-600"
                        >an article over the topic of communication
                      </span>
                    </div>
                  </Link>
                </div>
                <div class="w-full lg:w-11/12 px-4">
                  <Link to="/predictingChurn">
                    <div
                      class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg py-2"
                    >
                      <h5 class="text-xl font-semibold pb-4 text-center">
                        Random Forest
                      </h5>
                      <span
                        class="mx-auto leading-relaxed text-base text-gray-600"
                        >get familiar with using RandomForestClassifier for your
                        general classification needs
                      </span>
                    </div>
                  </Link>
                </div>

                <div class="w-full lg:w-11/12 px-4">
                  <Link to="/vigilone">
                    <div
                      class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg py-2"
                    >
                      <h5 class="text-xl font-semibold pb-4 text-center">
                        {"Vigil (pt.1)"}
                      </h5>
                      <span
                        class="mx-auto leading-relaxed text-base text-gray-600"
                        >get familiar with setting up a multipage application
                        utilizing the Dash framework
                      </span>

                      <!-- <img
                        alt="..."
                        class="align-middle border-none max-w-full h-auto rounded-lg"
                        src={vigilPic} /> -->
                    </div>
                  </Link>
                  <divider />
                </div>

                <div class="w-full lg:w-11/12 px-4 py-2">
                  <Link to="/yieldWhile">
                    <div
                      class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg py-2"
                    >
                      <h5 class="text-xl font-semibold pb-4 text-center">
                        Yield for a While
                      </h5>

                      <span
                        class="mx-auto leading-relaxed text-base text-gray-600"
                        >get familiar with yielding values from a loop
                      </span>
                      <!-- <img
                        alt="..."
                        class="align-middle border-none max-w-full h-auto rounded-lg"
                        src={yielPic} /> -->
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <p class="text-gray-600 text-lg leading-relaxed mt-4 mb-4">
          Cause if you do, it can be yours now. Hit the buttons below to
          navigate to get the Free version for your next project. Build a new
          web app or give an old project a new look!
        </p> -->

        <div class="text-center mt-16" />
      </div>
    </div>
  </div>
</section>
<Footer />
