<script>
  import { Link } from "svelte-routing";

  // core components
  import IndexNavbar from "components/Navbars/IndexNavbar.svelte";
  import Footer from "components/Footers/Footer.svelte";
  import { LottiePlayer } from "@lottiefiles/svelte-lottie-player";
  import { scrollto } from "svelte-scrollto";

  const patternVue = "/assets/img/pyDataAnalysis.png";
  const pCourse = "/assets/img/pyCourse.png";

  export let location;

  let controlsLayout = [
    "previousFrame",
    "playpause",
    "stop",
    "nextFrame",
    "progress",
    "frame",
    "loop",
    "spacer",
    "background",
    "snapshot",
    "zoom",
    "info",
  ];
</script>

<IndexNavbar />
<section class="header relative pt-16 items-center flex h-screen max-h-860-px">
  <div class="container mx-auto items-center flex flex-wrap">
    <div class="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
      <div class="pt-32 sm:pt-0">
        <h2 class="font-semibold text-4xl text-gray-700">
          Welcome to my Contracting Service!
        </h2>
        <p class="mt-4 text-lg leading-relaxed text-gray-600">
          I have worked at 4 different startups, I taught a Python basics course
          to a classroom of 25 business students and would like to help you in
          your endeavors.
        </p>
        <div class="mt-2">
          <div class="container mx-auto">
            <div class="flex flex-wrap items-center">
              <div class="w-10/12 md:w-12/12 lg:w-12/12 px-12 md:px-4 mr-auto ml-auto -mt-32">
                <div
                  class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blue-500"
                />
              </div>

              <div class="w-full md:w-12/12 px-4">
                <div class="flex flex-wrap">
                  <div class="w-full md:w-6/12 px-4">
                    <div class="relative flex flex-col mt-4">
                      <div class="px-4 py-5 flex-auto">
                        <div
                          class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                        >
                          <i class="fas fa-cogs" />
                        </div>
                        <h6 class="text-xl mb-1 font-semibold">Code Setup</h6>
                        <p class="mb-4 text-gray-600">
                          To get started I will show you how to setup a
                          professional environment so you can start coding right
                          away.
                        </p>
                      </div>
                    </div>
                    <div class="relative flex flex-col min-w-0">
                      <div class="px-4 py-5 flex-auto">
                        <div
                          class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                        >
                          <i class="fas fa-chart-bar" />
                        </div>
                        <h6 class="text-xl mb-1 font-semibold">
                          Analysis Fundamentals
                        </h6>
                        <p class="mb-4 text-gray-600">
                          You will learn how to analyze and visualize data using
                          a popular Data Science tool called Jupyter Notebook.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-6/12 px-4">
                    <div class="relative flex flex-col min-w-0 mt-4">
                      <div class="px-4 py-5 flex-auto">
                        <div
                          class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                        >
                          <i class="fas fa-code" />
                        </div>
                        <h6 class="text-xl mb-1 font-semibold">
                          Python Basics
                        </h6>
                        <p class="mb-4 text-gray-600">
                          I will show you the fundamentals of Python and how you
                          can use the language to build real world solutions.
                        </p>
                      </div>
                    </div>
                    <div class="relative flex flex-col min-w-0">
                      <div class="px-4 py-5 flex-auto">
                        <div
                          class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                        >
                          <i class="fas fa-lightbulb" />
                        </div>
                        <h6 class="text-xl mb-1 font-semibold">Intuition</h6>
                        <p class="mb-4 text-gray-600">
                          I want to give you all of the tools available online
                          for you to continue your education without my
                          assistance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hidden lg:block">
      <img
        class="absolute top-0 b-auto right-0 pt-16 sm:w-none -mt-48 sm:mt-0 w-10/12 max-h-860-px"
        style="width: 45%; height: 95%; margin-right: 0%; margin-top: 4%; cursor: pointer;"
        src={patternVue}
        alt="..."
      />
    </div>
  </div>
</section>

<section class="mt-48 md:mt-40 pb-40 relative bg-gray-200">
  <div
    id="scroll-element"
    class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
    style="transform: translateZ(0);"
  >
    <svg
      class="absolute bottom-0 overflow-hidden"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      version="1.1"
      viewBox="0 0 2560 100"
      x="0"
      y="0"
    >
      <polygon
        class="text-gray-200 fill-current"
        points="2560 0 2560 100 0 100"
      />
    </svg>
  </div>
  <div class="container mx-auto">
    <div class="flex flex-wrap items-center">
      <div
        class="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32"
      >
        <div
          class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blue-500"
        />
      </div>

      <div class="w-full md:w-6/12 px-4">
        <div class="flex flex-wrap">
          <div class="w-full md:w-6/12 px-4">
            <div class="relative flex flex-col mt-4">
              <div class="px-4 py-5 flex-auto">
                <div
                  class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                >
                  <i class="fas fa-cogs" />
                </div>
                <h6 class="text-xl mb-1 font-semibold">Code Setup</h6>
                <p class="mb-4 text-gray-600">
                  To get started I will show you how to setup a professional
                  environment so you can start coding right away.
                </p>
              </div>
            </div>
            <div class="relative flex flex-col min-w-0">
              <div class="px-4 py-5 flex-auto">
                <div
                  class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                >
                  <i class="fas fa-chart-bar" />
                </div>
                <h6 class="text-xl mb-1 font-semibold">
                  Analysis Fundamentals
                </h6>
                <p class="mb-4 text-gray-600">
                  You will learn how to analyze and visualize data using a
                  popular Data Science tool called Jupyter Notebook.
                </p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-6/12 px-4">
            <div class="relative flex flex-col min-w-0 mt-4">
              <div class="px-4 py-5 flex-auto">
                <div
                  class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                >
                  <i class="fas fa-code" />
                </div>
                <h6 class="text-xl mb-1 font-semibold">Python Basics</h6>
                <p class="mb-4 text-gray-600">
                  I will show you the fundamentals of Python and how you can use
                  the language to build real world solutions.
                </p>
              </div>
            </div>
            <div class="relative flex flex-col min-w-0">
              <div class="px-4 py-5 flex-auto">
                <div
                  class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                >
                  <i class="fas fa-lightbulb" />
                </div>
                <h6 class="text-xl mb-1 font-semibold">Intuition</h6>
                <p class="mb-4 text-gray-600">
                  I want to give you all of the tools available online for you
                  to continue your education without my assistance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <br />
  <br />
  <br />
  <div class="container mx-auto px-4 pb-32 pt-48">
    <br />
    <!-- <div class="items-center flex flex-wrap">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
            <div class="w-full lg:w-6/12 px-4">
              <div
                class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i class="fas fa-file-alt text-xl" />
              </div>
              <h3 class="text-3xl font-semibold">Contact</h3>
  
              <div class="relative w-full mb-3 mt-8">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  for="full-name">
                  Full Name
                </label>
                <input
                  id="full-name"
                  type="text"
                  class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="Full Name" />
              </div>
  
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  for="email">
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="Email" />
              </div>
  
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  for="message">
                  Message
                </label>
                <textarea
                  id="message"
                  rows="4"
                  cols="80"
                  class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                  placeholder="Type a message..." />
              </div>
              <div class="text-center mt-6">
                <button
                  class="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button">
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    <iframe
      title="Contact Form"
      class="airtable-embed airtable-dynamic-height"
      src="https://airtable.com/embed/shrAYX7Mvd6QYpq15?backgroundColor=blue"
      frameborder="0"
      onmousewheel=""
      width="100%"
      height="755"
      style="background: transparent; border: 1px solid #ccc;"
    />
  </div>
</section>

<section class="block relative z-1 bg-gray-700" />

<section class="pb-16 bg-gray-300 relative pt-8">
  <div
    class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
    style="transform: translateZ(0);"
  >
    <svg
      class="absolute bottom-0 overflow-hidden"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      version="1.1"
      viewBox="0 0 2560 100"
      x="0"
      y="0"
    >
      <polygon
        class="text-gray-300 fill-current"
        points="2560 0 2560 100 0 100"
      />
    </svg>
  </div>
</section>
<Footer />
