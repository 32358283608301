<script>
    import { scrollto } from "svelte-scrollto";
    import IndexNavbar from "components/Navbars/IndexNavbar.svelte";
    import Footer from "components/Footers/Footer.svelte";
    import { onMount } from "svelte";

    import { Highlight } from "svelte-highlight";
    import { python } from "svelte-highlight/languages";
    import { github } from "svelte-highlight/styles";

    import Formater from "./codeFormat.svelte";

    onMount(() => {
    window.scrollTo(0, 0);
  });

    // import { anOldHope } from "svelte-highlight/styles";

    const patternVue = "/assets/img/numArrays.jpg";
    const waves = "/assets/img/wave.png";
    const dashPic = "/assets/img/dash_pic.png";
    const aTitle = "Vigil: a Marketing Dashboard in Dash (pt.1)";

    $: virtualEnv = `mkdir Vigil
cd Vigil
mkdir dashboard
python3 -m venv vigil_dash
source vigil_dash/bin/activate   # to exit virtual environment type in: deactivate
cd dashboard
`;
    $: pyFileStructure = `|- dashboard
    |-- assets # create using: mkdir assets 
    |-- data
    |-- pages
        |- serpScraper.py
        |- nlpStats.py
        |- knowledgeGraph.py
        |- home.py
        |- blog.py
        |- overview.py
    |-- googler
    |- app.py # create using: touch app.py
    |- utils.py
    |- keywordQuery.py
    |- yourCreds.py
    |- requirements.txt`;
    $: pipInstalls = `pip install flask
pip install dash
pip install pandas
pip install scrapy
`;

$: appPyFile = `import flask
import dash
import dash_html_components as html
import dash_core_components as dcc
from dash.dependencies import Input, Output, State
from dash.exceptions import PreventUpdate
import pandas as pd
import time
# from keywordQuery import knowledge_graph, KG_API
from pages import (
    blog,
    serpScraper,
    home,
    knowledgeGraph,
    nlpStats,
    overview,
)

server = flask.Flask(__name__)

app = dash.Dash(
    __name__,
    server=server,
    suppress_callback_exceptions=True
)
app.layout = html.Div(
    [dcc.Location(id="url", refresh=True), html.Div(id="page-content")]
)

# Update page
@app.callback(Output("page-content", "children"), [Input("url", "pathname")])
def display_page(pathname):
    if pathname == "/blog":
        return blog.create_layout(app)
    elif pathname == "/graphapi":
        return knowledgeGraph.create_layout(app)
    elif pathname == "/serpscrape":
        return serpScraper.create_layout(app)
    elif pathname == "/":
        return home.create_layout(app)
    elif pathname == "/nlpstats":
        return nlpStats.create_layout(app)
    else:
        return overview.create_layout(app)

# all callbacks for pages go here

# Knowledge Graph Callback
# @app.callback(
#     Output("scraped", "data"),
#     [Input('button', 'n_clicks')],
#     state=[State("input2", "value")]
# )
# def update_output(n_clicks, input2):
#     if n_clicks is None:
#         raise PreventUpdate
#     else:
#         searched2 =  knowledge_graph(key=KG_API, query=input2)
#         searched2['#'] = list(range(1, len(searched2) + 1))
#         return searched2.to_dict('rows')
        
        
# fyi: keeping data queries withing data callbacks ensures fresh data is coming in upon input change

if __name__ == '__main__':
    app.run_server(debug=False)`;

    $: lastBlock = `import dash_html_components as html
from utils import Header

def create_layout(app):
    return html.Div([
        html.H6("This is page x"),
        html.Div([Header(app)])
    ], className="page")`;

    $: code3 = `import dash_html_components as html
import dash_core_components as dcc


def Header(app):
    return html.Div([get_header(app), html.Br([])])

def get_header(app):
    header = html.Div(
        [
            html.Div(
                [
                    html.Div(
                        [dcc.Link([
                            # !!!! CHANGE OUT THIS FILE TO YOUR FILE OF CHOICE TO GET A LOGO AT THE TOP LEFT !!!!
                            html.Img(src=app.get_asset_url("logoGR.png"), style={"marginTop": "12px", "marginLeft": "24px"}, className="logo")
                        ],
                                  href="/")],
                        className="five columns",
                    ),
                    html.Div([
                        html.Div(
                            [
                                dcc.Link(
                                    "Portfolio",
                                    href="/",
                                    className="tab first",
                                ),
                                html.A(
                                    "Blog",
                                    href="https://george-reyes.com/",
                                    className="tab",
                                ),
                                dcc.Link(
                                    "SERP Scraper",
                                    href="/serpscrape",
                                    className="tab",
                                ),
                                dcc.Link(
                                    "Knowledge Graph API",
                                    href="/graphapi",
                                    className="tab",
                                ),
                                dcc.Link(
                                    "NLP Analysis",
                                    href="/nlpstats",
                                    className="tab",
                                )
                            ],
                            className="row all-tabs",
                        )
                    ], className="seven columns")
                ],
                className="twelve columns",
                style={"padding-left": "0", "color": "white"},
            )
        ],
        className="row", style={"background-color": "white", "padding-bottom": "10px"}
    )
    return header



def make_dash_table(df):
    """ Return a dash definition of an HTML table for a Pandas dataframe """
    table = []
    for index, row in df.iterrows():
        html_row = []
        for i in range(len(row)):
            html_row.append(html.Td([row[i]]))
        table.append(html.Tr(html_row))
    return table
`;
</script>

<svelte:head>
    {@html github}
</svelte:head>

<!-- <svelte:head>
    <title>About</title>
  </svelte:head> -->

<IndexNavbar />

<!-- <div class="flex flexrow hidden lg:block">
    <section class="section2">
        <div class="sineWaves"></div>
    </section>
</div>
<div class="flex flexrow md:hidden sm:block">
</div> -->

<div class="container px-5 pt-24 mx-auto">
    <div class="flex flex-col text-center w-full mb-12">
        <h1
            class="sm:text-3xl title-font mt-32 mb-4 font-semibold text-4xl text-gray-700">
            {aTitle}
        </h1>
        <div flex flex-row>
            <span
                class="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-600">
                This article should help you get familiar with setting up a multipage application utilizing the Dash framework.
            </span>
            <br>
            <a
            href="https://github.com/reyesGeorge/Vigil-Marketing-Dashboard"
            ><span>[Entire Code on GitHub]</span></a>
            <!-- <a
            href="https://developers.google.com/edu/python/lists"
            ><span>[List]</span></a> -->
            <img
      class="b-auto right-0 pt-16 sm:w-none -mt-48 sm:mt-0 w-10/12 max-h-860-px"
      style="width: 100%; height: 95%; margin-right: 8%; margin-top: 2%;"
      src={dashPic}
      alt="..."
      />
        </div>
    </div>
</div>

<!-- First Text -->
<div class="flex flex-row w-screen relative justify-center items-center">
    <div class="w-8/12">
        <span class="mx-auto leading-relaxed text-base text-gray-600">
            To show how
        </span>
        <span class="mx-auto leading-relaxed text-base text-gray-600">
            to do this I will make a marketing dashboard with a broad range of functionality.
            The features will include:
        </span>
        <ul style="padding-left: 25px">
        <li class="mx-auto leading-relaxed text-base text-gray-600">
            - a Google Knowledge Graph API integration for Entity Searching
        </li>
        <li class="mx-auto leading-relaxed text-base text-gray-600">
            - a Scrapy Scraper for Google SERP Results
        </li>
        <li class="mx-auto leading-relaxed text-base text-gray-600">
            - a Response parser - for Dataframe Visualizations
        </li>
    </ul>
        <br>
        <span class="mx-auto leading-relaxed text-base text-gray-600">
            To get started open up your Python editor and setup a virtual environment by inputting the lines below in your terminal.
        </span>

        <div class="p-2 pl-4 code text-sm font-mono px-8 py-6">
            <Formater code={virtualEnv} />
        </div>

        <span class="mx-auto leading-relaxed text-base text-gray-600">
            Now we must set up our project structure as pictured below:
        </span>
        
        <div class="p-2 pl-4 code text-sm font-mono px-8 py-6">
            <Formater code={pyFileStructure} />
        </div>
        <p class="mx-auto leading-relaxed text-base text-gray-600">
            
        </p>

        <span class="mx-auto leading-relaxed text-base text-gray-600">
            If you are unfamiliar with the command line then look into the commands "mkdir" to make folders and "touch" to make a new file. Alternatively go into your folder system and manually make each folder and file.
        </span>
        <br>
        <br>
        <span class="mx-auto leading-relaxed text-base text-gray-600">
            Now it's time to install our packages: Pandas, Flask, Dash and Scrapy
        </span>
        
    </div>
</div>

<!-- Block of Code -->
<div class="flex flex-row w-screen relative justify-center items-center">
    <div class="w-8/12">
        <!-- <div class="p-2 code text-sm font-mono px-8 py-6 bg-gray-200 flex flex-row justify-between"> -->
        <div class="p-2 pl-4 code text-sm font-mono px-8 py-6">
            <!-- <Highlight language={python} {code} /> -->

            <Formater code={pipInstalls} />
        </div>
    </div>
</div>

<!-- Second Text -->
<div class="flex flex-row w-screen relative justify-center items-center">
    <div class="w-8/12">
        <span class="mx-auto leading-relaxed text-base text-gray-600">
        It's time to setup our Flask app utilizing the Dash framework.
        </span>
        <span class="mx-auto leading-relaxed text-base text-gray-600">
            Head over to your app file and copy the code below:
        </span>
        
    </div>
</div>
<!-- Second Block of Code -->
<div class="flex flex-row w-screen relative justify-center items-center">
    <div class="w-8/12">
        <!-- <div class="p-2 code text-sm font-mono px-8 py-6 bg-gray-200 flex flex-row justify-between"> -->
        <div class="p-2 pl-4 code text-sm font-mono px-8 py-6">
            <!-- <Highlight language={python} {code} /> -->

            <Formater code={appPyFile} />
        </div>
    </div>
</div>
<div class="flex flex-row w-screen relative justify-center items-center">
    <div class="w-8/12">
        <span class="mx-auto leading-relaxed text-base text-gray-600">
            There is a lot going on in the file above, especially if you are not familiar with the codebase. Essentially what you need to understand is that Dash is powered by Flask under the hood.
            You can use Flask for many different applications but in this instance we are using it to power our server so we can visualize our code in the browser.
        </span>
        <br>
        <br>
        <span class="mx-auto leading-relaxed text-base text-gray-600">
            The function display_page() I wrote above serves as an if statement that switches the routes of the application, this serves to make the app have "multiple" pages or tabs. If you look at the code, once a URL is clicked, depending on the pathname we call its corresponding function so that we may render it.
        </span>
        <br>
        <br>
        <span class="mx-auto leading-relaxed text-base text-gray-600">
            The @app.callback and update_output() function serve as our state management of sorts. Depending on your skill level as a developer you may already be familiar with similar libraries such as: Provider/BLoC in Dart or RxJS in JavaScript. If you are not then here
            is a quick breakdown, you must remember that your application is dumb, just because you pass in data/inputs does not mean that the app will now how to handle/react to those inputs. So you need to create some logic to tell your application what to do once a certain input/data is received.
        </span>
        <br>
        <br>
        <span class="mx-auto leading-relaxed text-base text-gray-600">
            Next step is to make our header so we have a menu at the top right of our screen. To make this menu go to your utils file and copy in the code below:
        </span>
        <br>
        <br>
        <span class="mx-auto leading-relaxed text-base text-gray-600">You will need to add in your own image where I state in the comment below, if you do not have one here is mine:</span>
        <a
        href="/assets/img/logoGR.png"
            ><span>[GR]</span></a>
    </div>
</div>

<!-- Third Block of Code -->
<div class="flex flex-row w-screen relative justify-center items-center">
    <div class="w-8/12">
        <!-- <div class="p-2 code text-sm font-mono px-8 py-6 bg-gray-200 flex flex-row justify-between"> -->
        <div class="p-2 pl-4 code text-sm font-mono px-8 py-6">
            <!-- <Highlight language={python} {code} /> -->

            <Formater code={code3} />
        </div>
    </div>
</div>
<!-- Last Block of Text -->
<div class="flex flex-row w-screen relative justify-center items-center">
    <div class="w-8/12">
        <span class="mx-auto leading-relaxed text-base text-gray-600">
            To style our application I scraped an open source bootstrap css theme recommended by the Dash Framework, you should download the files and place them inside your assets folder:
        </span>
        <a
        href="https://github.com/reyesGeorge/Vigil-Marketing-Dashboard/tree/main/assets"
            ><span>[assets]</span></a>
    </div>
</div>
<br>

<!-- Second Block of Text -->
<div class="flex flex-row w-screen relative justify-center items-center">
    <div class="w-8/12">
        <span class="mx-auto leading-relaxed text-base text-gray-600">
            The last step in the first part of this tutorial is to add the code below for each of your page files under the pages directory. The other tutorials will focus on fleshing out each of these pages with common marketing tools, but for the sake of brevity we will end this one here.
        </span>
    </div>
</div>

<!-- Third Block of Code -->
<div class="flex flex-row w-screen relative justify-center items-center">
    <div class="w-8/12">
        <!-- <div class="p-2 code text-sm font-mono px-8 py-6 bg-gray-200 flex flex-row justify-between"> -->
        <div class="p-2 pl-4 code text-sm font-mono px-8 py-6">
            <!-- <Highlight language={python} {code} /> -->

            <Formater code={lastBlock} />
        </div>
    </div>
</div>


<div class="pb-20"><br /></div>
<div class="pb-20"><br /></div>

<Footer />
