<!-- App.svelte -->
<script>
  import { Router, Route } from "svelte-routing";

  // Blog Pages
  import YieldWhile from "./views/auth/blogs/yieldWhile.svelte";
  import Vigil1 from "./views/auth/blogs/vigil1.svelte";
  import ChurnRandFor from "./views/auth/blogs/predictingChurnRandFor.svelte";
  import Communication from "./views/auth/blogs/communication.svelte";

  // Contracting Page
  import Contracting from "./views/auth/Contracting.svelte";

  // No Layout Pages
  import Index from "./views/Index.svelte";
  import Impact from "./views/auth/blogs/impact.svelte";

  import Sociopath from "./views/auth/blogs/sociopath.svelte";

  export let url = "";
</script>

<Router url="{url}">

  <!-- Tutor Button -->
  <Route path="contracting" component="{Contracting}" />

  <!-- Blog Pages -->
  <Route path="yieldWhile" component="{YieldWhile}" />
  <Route path="vigilone" component="{Vigil1}" />

  <Route path="predictingChurn" component="{ChurnRandFor}" />
  <Route path="communication" component="{Communication}" />
  <Route path="impact" component="{Impact}" />
  <!-- <Route path="sociopathcia" component="{Sociopath}" /> -->
  
  <!-- <Route path="saasRevenue" component="{ChurnRandFor}" /> -->

  <!-- <Route path="landing" component="{Landing}" /> -->
  <!-- <Route path="profile" component="{Profile}" /> -->
  <Route path="/" component="{Index}" />
</Router>
