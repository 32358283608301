<script>
    import { Highlight } from "svelte-highlight";
    import { python } from "svelte-highlight/languages";
    import { github } from "svelte-highlight/styles";
    export let code;
</script>

<svelte:head>
    {@html github}
</svelte:head>



<Highlight language={python} {code}/>